<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card">
          <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item v-if="false" title="Global Settings">
              <template #default>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="false" title="Carrier Account">
              <template #default>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Bundle/Multipack Fee">
              <template #default>
                <bundle-multipack-fee-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="FBM Charges">
              <template #default>
                <fbm-charge-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Item Sort Fee">
              <template #default>
                <item-sort-fee-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Service Charge Per Box">
              <template #default>
                <per-box-charge-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Storage Fee Setup">
              <template #default>
                <storage-fee-setup-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Problem Handling Fee">
              <template #default>
                <problem-charge-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Disposition Fee">
              <template #default>
                <disposition-fee-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Oversize Item Fee">
              <template #default>
                <oversize-item-fee-settings />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission" title="Shipping Upcharges">
              <template #default>
                <shipping-settings />
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    'shipping-settings': () => import('./fee-settings/ShippingSettings.vue'),
    'per-box-charge-settings': () => import('./fee-settings/PerBoxChargeSettings.vue'),
    'item-sort-fee-settings': () => import('./fee-settings/ItemSortFeeSettings.vue'),
    'bundle-multipack-fee-settings': () => import('./fee-settings/BundleMultipackFeeSettings.vue'),
    'fbm-charge-settings': () => import('./fee-settings/FbmChargeSettings.vue'),
    'problem-charge-settings': () => import('./fee-settings/ProblemChargeSettings.vue'),
    'storage-fee-setup-settings': () => import('./fee-settings/StorageFeeSetupSettings.vue'),
    'oversize-item-fee-settings': () => import('./fee-settings/OversizeItemFeeSettings.vue'),
    'disposition-fee-settings': () => import('./fee-settings/DispositionFeeSettings.vue'),
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_accounting_invoice_list')
    },
  },
}
</script>

<style lang="scss">
.dx-tab-selected {
  background-color: #1d9bd9;
  .dx-tab-text {
    color: #dceaf1;
  }
  .dx-icon {
    color: #dceaf1;
  }
}

.dx-tabs {
  border-radius: 4px 4px 0 0;
}
.dx-tab {
  border-radius: 4px 4px 0 0;
}

</style>
